"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsentTemplateCode = exports.ConsentType = void 0;
const mobx_1 = require("mobx");
const ApiUtil_1 = __importStar(require("../util/ApiUtil"));
var ConsentType;
(function (ConsentType) {
    ConsentType["TRAFFIC_DATA_ANALYSIS"] = "TRAFFIC_DATA_ANALYSIS";
    ConsentType["DIRECT_MARKETING"] = "DIRECT_MARKETING";
    ConsentType["LOCATION_DATA_ANALYSIS"] = "LOCATION_DATA_ANALYSIS";
})(ConsentType || (exports.ConsentType = ConsentType = {}));
var ConsentTemplateCode;
(function (ConsentTemplateCode) {
    ConsentTemplateCode["ON_SCREEN"] = "ON_SCREEN";
})(ConsentTemplateCode || (exports.ConsentTemplateCode = ConsentTemplateCode = {}));
class ConsentStore {
    constructor(rootStore) {
        Object.defineProperty(this, "trafficDataAnalysis", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: this.initConsent({
                type: ConsentType.TRAFFIC_DATA_ANALYSIS
            })
        });
        Object.defineProperty(this, "directMarketing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: this.initConsent({
                type: ConsentType.DIRECT_MARKETING
            })
        });
        Object.defineProperty(this, "locationDataAnalysis", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: this.initConsent({
                type: ConsentType.LOCATION_DATA_ANALYSIS
            })
        });
        Object.defineProperty(this, "confirmLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "initialized", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "confirmError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ssoStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "marketingChannelsStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "onlineHelpEventStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "init", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                this.initialized = false;
                await this.initConsents();
                this.confirmLoading = false;
                this.confirmError = null;
                this.initialized = true;
                window.parent.postMessage('GdprChoicesReady', '*');
            }
        });
        Object.defineProperty(this, "changeTrafficDataAnalysis", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (allowed) => {
                this.changeConsent(allowed, this.trafficDataAnalysis);
            }
        });
        Object.defineProperty(this, "postTrafficDataAnalysis", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                return this.changeConsent(this.trafficDataAnalysis.allowed, this.trafficDataAnalysis);
            }
        });
        Object.defineProperty(this, "postLocationDataAnalysis", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                return this.changeConsent(this.locationDataAnalysis.allowed, this.locationDataAnalysis);
            }
        });
        Object.defineProperty(this, "postDirectMarketing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                await this.changeConsent(this.directMarketing.allowed, this.directMarketing);
            }
        });
        Object.defineProperty(this, "changeDirectMarketing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (allowed) => {
                this.changeConsent(allowed, this.directMarketing);
            }
        });
        Object.defineProperty(this, "changeLocationInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (allowed) => {
                this.changeConsent(allowed, this.locationDataAnalysis);
            }
        });
        Object.defineProperty(this, "allowConsent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (consent, allowed) => {
                consent.allowed = allowed;
            }
        });
        Object.defineProperty(this, "sendConsentCheckedEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (type, isSelected) => {
                await this.onlineHelpEventStore.recordSimpleEventWithCustomerType(`${type}_${isSelected ? 'YES' : 'NO'}`);
            }
        });
        Object.defineProperty(this, "sendConsentDetailsEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (type) => {
                await this.onlineHelpEventStore.recordSimpleEventWithCustomerType(`${type}_DETAILS`);
            }
        });
        (0, mobx_1.makeObservable)(this);
        this.ssoStore = rootStore.ssoStore;
        this.marketingChannelsStore = rootStore.marketingChannelsStore;
        this.onlineHelpEventStore = rootStore.onlineHelpEventStore;
        (0, mobx_1.reaction)(() => this.ssoStore.initStores, this.init);
        this.initialized = true;
    }
    async initConsents() {
        await this.getSignedConsents()
            .then((0, mobx_1.action)(({ data: { consents } }) => {
            this.trafficDataAnalysis = this.findOrInitializeNewConsent(consents, ConsentType.TRAFFIC_DATA_ANALYSIS);
            this.directMarketing = this.findOrInitializeNewConsent(consents, ConsentType.DIRECT_MARKETING);
            this.locationDataAnalysis = this.findOrInitializeNewConsent(consents, ConsentType.LOCATION_DATA_ANALYSIS);
        }))
            .catch((err) => console.error(err));
    }
    findOrInitializeNewConsent(consents, type) {
        const consent = consents.find((c) => c.type === type) || { type };
        return this.initConsent(consent);
    }
    isChanged(consent) {
        return consent.allowed !== consent.previousAllowed;
    }
    initConsent(consent) {
        return {
            type: consent.type,
            allowed: !!consent.value,
            previousAllowed: consent.value,
            linksJwt: consent.linksJwt,
            previousLinksJwt: consent.linksJwt,
            loading: false,
            initValue: consent.value
        };
    }
    get consentsHaveChanged() {
        return (this.trafficDataAnalysis.allowed !== this.trafficDataAnalysis.initValue ||
            this.locationDataAnalysis.allowed !== this.locationDataAnalysis.initValue ||
            (!this.ssoStore.isBusinessCustomer && this.directMarketing.allowed !== this.directMarketing.initValue));
    }
    hideDocumentLink(consent) {
        return this.isChanged(consent) && this.ssoStore.isDealer;
    }
    updateConsentLinksJwt(type, linksJwt) {
        if (type === ConsentType.TRAFFIC_DATA_ANALYSIS) {
            this.trafficDataAnalysis.linksJwt = linksJwt;
        }
        if (type === ConsentType.DIRECT_MARKETING) {
            this.directMarketing.linksJwt = linksJwt;
        }
        if (type === ConsentType.LOCATION_DATA_ANALYSIS) {
            this.locationDataAnalysis.linksJwt = linksJwt;
        }
    }
    get changedConsents() {
        const consents = [];
        if (this.isChanged(this.trafficDataAnalysis)) {
            consents.push(this.trafficDataAnalysis);
        }
        if (this.isChanged(this.directMarketing) && this.ssoStore.isPrivateCustomer) {
            consents.push(this.directMarketing);
        }
        if (this.isChanged(this.locationDataAnalysis)) {
            consents.push(this.locationDataAnalysis);
        }
        return consents;
    }
    restoreConsentsPreviousSelections() {
        this.changedConsents.forEach(this.restoreConsentPreviousSelections);
    }
    restoreConsentPreviousSelections(consent) {
        consent.allowed = consent.previousAllowed;
        consent.linksJwt = consent.previousLinksJwt;
    }
    toggleConfirmload(value) {
        this.confirmLoading = value;
    }
    setConfirmError(error) {
        this.confirmError = error;
    }
    get gaEventConfirmedB2cChoices() {
        return `${this.sharedConsentChoices}_direct-marketing-${this.directMarketing.allowed ? 'yes' : 'no'}`;
    }
    get gaEventConfirmedB2bChoices() {
        return this.sharedConsentChoices;
    }
    get sharedConsentChoices() {
        return (`traffic-data-analysis-${this.trafficDataAnalysis.allowed ? 'yes' : 'no'}` + `_location-data-analysis-${this.locationDataAnalysis.allowed ? 'yes' : 'no'}`);
    }
    get allB2CConsentsTrue() {
        return this.trafficDataAnalysis.allowed && this.locationDataAnalysis.allowed && this.directMarketing.allowed;
    }
    get allB2BConsentsTrue() {
        return this.trafficDataAnalysis.allowed && this.locationDataAnalysis.allowed;
    }
    get globalLoading() {
        return !this.initialized;
    }
    get trafficDataEnabled() {
        return this.ssoStore.canRepresent;
    }
    get directMarketingEnabled() {
        return this.ssoStore.canRepresent && this.ssoStore.isPrivateCustomer;
    }
    get locationDataEnabled() {
        return this.ssoStore.canRepresent;
    }
    get confirmEnabled() {
        return this.ssoStore.canRepresent && this.ssoStore.isDealerOrHasNoStipulation;
    }
    async confirm() {
        this.confirmLoading = true;
        this.confirmError = null;
        try {
            await Promise.all(this.changedConsents.map((consent) => this.changeConsent(consent.allowed, consent)));
            if (!this.confirmError) {
                const promises = this.changedConsents.map((consent) => this.postConfirm(consent.linksJwt));
                await Promise.all(promises);
                this.changedConsents.forEach((consent) => (consent.previousAllowed = consent.allowed));
                this.init();
            }
        }
        catch (e) {
            this.confirmError = e;
            this.ssoStore.logoutAndReloadIfNeeded(e.response);
        }
        finally {
            this.confirmLoading = false;
        }
    }
    async changeConsent(allowed, consent) {
        if (this.ssoStore.isDealer) {
            consent.allowed = allowed;
            return;
        }
        if (consent.previousAllowed === allowed) {
            this.restoreConsentPreviousSelections(consent);
            return;
        }
        consent.loading = true;
        try {
            consent.linksJwt = await this.postConsent(allowed, consent.type);
            consent.allowed = allowed;
        }
        catch (e) {
            this.confirmError = e;
            this.ssoStore.logoutAndReloadIfNeeded(e.response);
        }
        finally {
            consent.loading = false;
        }
    }
    getDocumentLinkInline(jwtToken) {
        return `${ApiUtil_1.apiBaseUrl}/consent/document-inline?jwt=${jwtToken}`;
    }
    getRequests(status) {
        return ApiUtil_1.default.get(`/data-gathering/requests/${status}`).then((response) => response.data.content);
    }
    getAttachments(id) {
        return ApiUtil_1.default.get(`/data-gathering/requests/${id}/attachments/all`, {
            responseType: 'blob',
            timeout: 10000
        });
    }
    postConsent(value, type) {
        const data = { value, type: type.valueOf() };
        return ApiUtil_1.default.post('/consent/', data).then((response) => response.data);
    }
    postConfirm(jwtToken) {
        return ApiUtil_1.default.post(`/consent/click-accept?jwt=${jwtToken}`, {}, { timeout: 30000 });
    }
    async getSignedConsents() {
        return ApiUtil_1.default.get('/consent');
    }
    getLegalEntitiesWithNotGivenConsents() {
        return ApiUtil_1.default.get('/consent/legal/not-given').then((response) => response.data.legalEntities);
    }
}
__decorate([
    mobx_1.observable
], ConsentStore.prototype, "trafficDataAnalysis", void 0);
__decorate([
    mobx_1.observable
], ConsentStore.prototype, "directMarketing", void 0);
__decorate([
    mobx_1.observable
], ConsentStore.prototype, "locationDataAnalysis", void 0);
__decorate([
    mobx_1.observable
], ConsentStore.prototype, "confirmLoading", void 0);
__decorate([
    mobx_1.observable
], ConsentStore.prototype, "initialized", void 0);
__decorate([
    mobx_1.observable
], ConsentStore.prototype, "confirmError", void 0);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "consentsHaveChanged", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "changedConsents", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "gaEventConfirmedB2cChoices", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "gaEventConfirmedB2bChoices", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "sharedConsentChoices", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "allB2CConsentsTrue", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "allB2BConsentsTrue", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "globalLoading", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "trafficDataEnabled", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "directMarketingEnabled", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "locationDataEnabled", null);
__decorate([
    mobx_1.computed
], ConsentStore.prototype, "confirmEnabled", null);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "changeTrafficDataAnalysis", void 0);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "postTrafficDataAnalysis", void 0);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "postLocationDataAnalysis", void 0);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "postDirectMarketing", void 0);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "changeDirectMarketing", void 0);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "changeLocationInfo", void 0);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "allowConsent", void 0);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "confirm", null);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "changeConsent", null);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "getDocumentLinkInline", null);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "getRequests", null);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "getAttachments", null);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "postConsent", null);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "postConfirm", null);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "getSignedConsents", null);
__decorate([
    mobx_1.action
], ConsentStore.prototype, "getLegalEntitiesWithNotGivenConsents", null);
exports.default = ConsentStore;
