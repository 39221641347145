"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GaDataStore = void 0;
const ParamsUtil_1 = require("../util/ParamsUtil");
class GaDataStore {
    static push(data, params) {
        if (window.dataLayer) {
            const { eventLabel, eventAction } = data;
            const label = eventLabel && params ? (0, ParamsUtil_1.parseParams)(eventLabel, params) : eventLabel;
            const action = eventAction && params ? (0, ParamsUtil_1.parseParams)(eventAction, params) : eventAction;
            window.dataLayer.push({
                ...data,
                eventAction: action,
                eventLabel: label
            });
        }
    }
    static pushAnalyticsObject(page, id) {
        GaDataStore.analyticsTriggerPush({
            event: 'AutoEvent',
            group: 'brandfree-web',
            page,
            id
        });
    }
    static analyticsTriggerPush(data) {
        var _a;
        if (window.dataLayer) {
            (_a = window.dataLayer) === null || _a === void 0 ? void 0 : _a.push({
                event: data.event,
                eventCategory: data.page,
                eventLabel: data.id,
                eventAction: data.group
            });
        }
    }
}
exports.GaDataStore = GaDataStore;
exports.default = GaDataStore;
