"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_MARKETING_CHANNELS = exports.MarketingChannelType = void 0;
var MarketingChannelType;
(function (MarketingChannelType) {
    MarketingChannelType["EMAIL"] = "EMAIL";
    MarketingChannelType["SMS"] = "SMS";
})(MarketingChannelType || (exports.MarketingChannelType = MarketingChannelType = {}));
const GET_MARKETING_CHANNELS = () => [
    {
        type: MarketingChannelType.EMAIL,
        checked: false,
        label: 'marketing_channel.email'
    },
    {
        type: MarketingChannelType.SMS,
        checked: false,
        label: 'marketing_channel.sms'
    }
];
exports.GET_MARKETING_CHANNELS = GET_MARKETING_CHANNELS;
